<template>
  <div>
    <!-- disable -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-danger"
      v-if="isEnabled"
      v-b-tooltip.hover="'Dezactivează'"
      v-on:click="disable"
    >
      <i class="fas fa-ban text-danger" />
    </b-button>

    <!-- enable -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-success"
      v-else
      v-b-tooltip.hover="'Activează'"
      v-on:click="enable"
    >
      <i class="far fa-check-circle text-success" />
    </b-button>

    <!-- reinstate -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-success"
      v-if="isSuspended"
      v-b-tooltip.hover="'Deblochează'"
      v-on:click="reinstate"
    >
      <i class="fas fa-lock-open text-success" />
    </b-button>

    <!-- suspend -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-danger"
      v-else
      v-b-tooltip.hover="'Blochează'"
      v-on:click="suspend"
    >
      <i class="fas fa-lock text-danger" />
    </b-button>

    <!-- rename -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-primary"
      v-b-tooltip.hover="'Redenumește'"
      v-on:click="rename"
    >
      <i class="fas fa-edit text-primary" />
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    isEnabled() {
      return !!this.customer.enabled;
    },

    isSuspended() {
      return this.customer.suspended;
    },
  },

  methods: {
    enable() {
      this.$emit("enable", this.customer);
    },

    disable() {
      this.$emit("disable", this.customer);
    },

    suspend() {
      this.$emit("suspend", this.customer);
    },

    reinstate() {
      this.$emit("reinstate", this.customer);
    },

    rename() {
      this.$emit("rename", this.customer);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-right: 0.5rem;

  &:last-child {
    margin: 0;
  }
}
</style>
