<template>
  <b-table
    class="table-head-custom"
    primary-key="id"
    stacked="md"
    show-empty
    small
    fixed
    no-sort-reset
    no-local-sorting
    v-bind:busy="loading"
    v-bind:fields="fields"
    v-bind:items="customers"
    v-bind:sort-by.sync="sortBy"
    v-bind:sort-desc.sync="sortDesc"
    v-on:sort-changed="sortChanged"
  >
    <template v-slot:head()="row">
      <template v-if="sortBy === row.column">
        {{ row.label }}

        <i v-if="sortDesc" class="fas fa-sort-amount-down" />
        <i v-else class="fas fa-sort-amount-up" />
      </template>

      <template v-else>
        {{ row.label }}
      </template>
    </template>

    <template v-slot:cell(name)="row">
      <div class="d-flex">
        <div class="symbol symbol-40 symbol-light" v-b-tooltip.hover="statusLabels[row.item.status]">
          <span class="symbol-label">
            <i v-bind:class="[ statusIcons[row.item.status], `text-${statusVariants[row.item.status]}` ]" />
          </span>
        </div>

        <div class="ml-2">
          <div class="text-dark-75 font-weight-bolder font-size-lg">{{ row.item.name }}</div>
          <div class="text-muted text-hover-info font-weight-bold">{{ row.item.phone | phone }}</div>
        </div>
      </div>
    </template>

    <template v-slot:cell(created)="row">
      <span v-b-tooltip.hover v-bind:title="row.item.created | timestamp">
        {{ row.item.created | ago }}
      </span>
    </template>

    <template v-slot:cell(status)="row">
      <CustomerSuspension
        v-if="row.item.suspended"
        v-bind:suspension="row.item.suspended"
      />

      <Label
        v-else
        kind="inline"
        size="lg"
        weight="bold"
        v-bind:variant="statusVariants[row.item.status] ? `light-${statusVariants[row.item.status]}` : 'light'"
      >
        {{ statusLabels[row.item.status] }}
      </Label>
    </template>

    <template v-slot:cell(lastOrder)="row">
      <Order
        class="bg-light"
        v-if="row.item.lastOrder"
        v-bind:disabled="true"
        v-bind:order="row.item.lastOrder"
      />
    </template>

    <template v-slot:cell(actions)="row">
      <CustomerActions
        v-bind:customer="row.item"
        v-on="$listeners"
      />
    </template>
  </b-table>
</template>

<script>
import moment from "moment";

import { formatPhoneNumber } from "@/core/utils";

import TableMixin from "@/view/mixins/Table.js";

import Label from "@/view/content/widgets/Label.vue";
import Order from "@/view/content/widgets/Order.vue";

import CustomerActions from "./CustomerActions";
import CustomerSuspension from "./CustomerSuspension";

export default {
  components: {
    CustomerActions,
    CustomerSuspension,
    Label,
    Order,
  },

  filters: {
    ago:       timestamp => moment(timestamp).fromNow(),
    duration:  duration  => moment.duration(duration).humanize(),
    timestamp: timestamp => moment(timestamp).format('D MMMM Y [la] HH:mm:ss'),
    phone: formatPhoneNumber,
  },

  mixins: [
    TableMixin
  ],

  props: {
    customers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        { key: "id",        label: "#",              thClass: "id",       sortable: true, sortDirection: "asc" },
        { key: "name",      label: "Nume",           thClass: "customer", sortable: true, sortDirection: "asc" },
        { key: "created",   label: "Cont creat",     thClass: "created",  sortable: true, sortDirection: "asc" },
        { key: "status",    label: "Stare cont",     thClass: "status" },
        { key: "lastOrder", label: "Ultima comandă", thClass: "order" },
        { key: "actions",   label: "Acțiuni",        thClass: "actions" }
      ],

      statusIcons: Object.freeze({
        disabled:  "fas fa-lock",
        suspended: "fas fa-minus-circle",
        paired:    "fas fa-mobile-alt",
        confirmed: "fas fa-user-check",
        enabled:   "fas fa-user-times",
      }),

      statusLabels: Object.freeze({
        disabled:  "blocat permanent",
        suspended: "blocat temporar",
        paired:    "aplicație activată",
        confirmed: "Nr. telefon confirmat",
        enabled:   "Cont nefolosit",
      }),

      statusVariants: Object.freeze({
        disabled:  "danger",
        suspended: "danger",
        paired:    "success",
        confirmed: "primary",
        enabled:   "",
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
table::v-deep {
  > thead, tfoot {
    > tr {
      > th {
        padding: 0.75rem 1rem;
        background: none !important;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.id {
          width: 8rem;
        }

        &.created {
          width: 17rem;
        }

        &.status {
          width: 30rem;
        }

        &.order {
          width: 35rem;
        }

        &.actions {
          width: 13rem;
        }

        &[aria-sort = ascending], &[aria-sort = descending] {
          color: $primary !important;

          i {
            color: $primary;
            font-size: 0.9rem;
            vertical-align: middle;
          }
        }
      }
    }
  }

  > tbody {
    > tr {
      > td {
        padding: 1rem;
        height: 11rem;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
